import { Container, Flex, Stack } from '@chakra-ui/react'
import React from 'react'

const GameContainer = ({ bg, ...props }) => {
  return (
    <Flex flexDirection="column" bg={bg} mt="0" minHeight="100%" justifyContent={`center`} py="8">
      <Container maxWidth={`container.md`} as={Stack} alignItems={`center`} {...props} />
    </Flex>
  )
}

export default GameContainer
