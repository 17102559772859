import { Box, Stack } from '@chakra-ui/react'
import React from 'react'
import Button from '../../../components/Button'
import { responsive } from '../../../components/ThemeProvider/theme'

const Question = ({ title, choices, onAnswer }) => {
  return (
    <Stack spacing={8} px="2">
      <Box
        rounded={`full`}
        py="1em"
        px="2em"
        fontSize={responsive('1.25em', '1.75em')}
        bg="white"
        textAlign={`center`}
        fontWeight={`bold`}
      >
        {title}
      </Box>
      <Stack
        direction={responsive('column', 'row')}
        spacing={4}
      >
        {choices.map((ch, i) => (
          <Button.Giloo
            key={i}
            w={`full`}
            py={responsive('2em', '3em')}
            fontSize={responsive('1.125em', '1.5em')}
            onClick={() => onAnswer(i)}
            whiteSpace={`wrap`}
          >
            {ch.label}
          </Button.Giloo>
        ))}
      </Stack>
    </Stack>
  )
}

export default Question
