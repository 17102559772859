import { Box, Container, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import sample from 'lodash/sample';
import { navigate } from 'gatsby';

import Progress from '../../../components/Progress'
// import { responsive } from '../../../components/ThemeProvider/theme'
import GameContainer from '../GameContainer'
import data from '../giloo/data.json'
import Question from './Question'
import useImages from '../../useImages';
import useAnswerTools from '../../useAnswerTools';
import Finished from '../../Finished'

const PlayGame = ({ location: { pathname } }) => {
  const images = useImages('giloo')
  const { questions, results } = data
  const answers = useRef()
  const [n, setCurrentN] = useState(0)
  const [finished, setFinished] = useState()
  const slickRef = useRef()
  const timeRef = useRef()
  const quizId = /^\/([^/]+)/.exec(pathname)
  const answerTools = useAnswerTools(quizId && quizId[1])
  const handleAnswer = (i) => (j) => {
    answers.current = answers.current ?? []
    if (i === questions.length - 1) {
      answers.current[i] = { ans: j, duration: new Date() - timeRef.current }
      const scores = answers.current.reduce((all, { ans }, q) => {
        const tags = questions[q]?.choices[ans]?.tags ?? []
        // console.log(q, tags)
        tags.forEach(tag => {
          all[tag] = all[tag] ?? 0
          all[tag] += 1
        })
        return all
      }, {})
      const maxScore = Math.max(...Object.values(scores))
      const resultKey = sample(Object.keys(scores).filter(k => scores[k] === maxScore))
      const result = results.find((r) => r.name === resultKey) ?? {}
      setFinished(true)
      // console.log(scores)
      // console.log(result)
      const go = () => navigate(pathname.replace('play', 'result'), {
        state: {
          result: {
            ...result,
            image: images[result.image],
          }
        }
      })
      answerTools.updateAnswer(answers.current, resultKey).then(go)
    } else {
      answers.current[i] = { ans: j, duration: new Date() - timeRef.current }
      setCurrentN(i + 1)
      slickRef.current?.slickGoTo(i + 1)
      timeRef.current = new Date()
    }
  }

  useEffect(() => {
    timeRef.current = new Date()
    answerTools.init()
  }, [])

  useEffect(() => {
    if (answerTools.inited) {
      timeRef.current = new Date()
    }
  }, [answerTools.inited])

  return (
    <GameContainer bg="giloo.yellow" maxWidth={`container.sm`}>
      <Box
        position="absolute"
        left="0"
        right="0"
        top="1em"
      >
        <Container maxWidth={`container.sm`}>
          <Progress
            value={n}
            total={questions.length}
            label={`${n + 1} / ${questions.length}`}
            color="giloo.purple"
            borderColor="giloo.green"
          />
        </Container>
      </Box>
      {finished ? (
        <Finished title="測驗結束！" />
      ) : (
        <Box w={`full`}>
          {!answerTools.inited && (
            <Stack alignItems={`center`}>
              <Text>測驗載入中...</Text>
              <Spinner />
            </Stack>
          )}
          <Box
            pointerEvents={!answerTools.inited && 'none'}
            opacity={answerTools.inited ? 1 : 0}
            height={answerTools.inited ? 'auto' : 0}
            overflow={`hidden`}
            position={`relative`}
          >
            <Slider
              accessibility={false}
              draggable={false}
              dots={false}
              infinite={false}
              arrows={false}
              swipe={false}
              touchMove={false}
              slidesToShow={1}
              slidesToScroll={1}
              ref={slickRef}
            >
              {questions.map((q, i) => (
                <div key={i}>
                  <Question {...q} onAnswer={handleAnswer(i)} />
                </div>
              ))}
            </Slider>
          </Box>
        </Box>
      )}


    </GameContainer>
  )
}

export default PlayGame
